import React from 'react'
import { Link } from 'gatsby'
import './Header.scss'
import Container from '../Container'

class Header extends React.Component {
  render () {
    return (
      <header
        className={
          this.props.location.pathname === '/'
            ? 'site-header'
            : 'site-header shadowed'
        }
      >
        <Container>
          <div className="site-branding">
            <Link to={'/'} className="site-title">
              {this.props.siteMeta.name}
            </Link>
          </div>
          <nav className="site-nav">
            <ul className="nav-list">
              <li className="nav-item">
                <Link to="/blog/">Blog</Link>
              </li>
              <li className="nav-item">
                <a href="https://www.urlencoder.io" target="_blank">
                  URLEncoder.io
                </a>
              </li>
            </ul>
          </nav>
        </Container>
      </header>
    )
  }
}

export default Header
